@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700;800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 400;
  background-color: #fff;
  color: #45436b;
  overflow-x: hidden;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #45436b;
}
.cta_list {
  animation: 1.5s ease 0s infinite beat;
  bottom: 50px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 20px;
  z-index: 10;
}
.cta_list li img {
  width: 80px;
}

.cta_list .round > div {
  width: 100px;
  height: 100px;
  background: linear-gradient(-45deg, #66f7dd, #52e4ef);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 50%;
}

.cta_list .round > div::before {
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, #66f7dd, #52e4ef);
  filter: blur(50px);
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
@keyframes beat {
  0%,
  50%,
  100% {
    transform: scale(1, 1);
  }
  30%,
  80% {
    transform: scale(0.92, 0.95);
  }
}
img.brand_logo {
  width: 170px;
}

.my-legend {
  background-color: red !important;
  padding: 40px;
}

.main_navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  padding: 10px 0;
}

.navbar-brand {
  outline: 0 !important;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  margin-top: 0px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar_right {
  display: flex;
  align-items: center;
  width: 100%;
}

.offcanvas .links {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.02);
  padding: 12px 34px;
  margin: 0 auto;
}

.offcanvas .links li {
  margin: 0 2px;
}

.offcanvas .links li a {
  color: #07053a !important;
  cursor: pointer;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 130px;
}

.offcanvas .links li a:hover,
.offcanvas .links li a.active {
  text-decoration: none !important;
  background: linear-gradient(45deg, #fceb76, #fae336);
}

.offcanvas .signin {
  background: transparent;
  font-weight: 600;
  border: 1px solid rgba(206, 243, 251, 80%) !important;
  color: #07053a;
}

.offcanvas .signin:hover {
  background: linear-gradient(106deg, #67ace4, #137ed4, #137ed4, #67ace4);
  color: #fff;
}

.offcanvas-backdrop {
  min-height: 100%;
}

.navbar-toggler-icon {
  filter: invert(1);
}

/* nav_inner */
.nav_inner {
  background: #f9f9f9;
  border-bottom: 1px solid #ededed;
  padding-bottom: 24px;
  margin-bottom: 25px;
  position: fixed;
  width: calc(100% - 153px);
  padding-top: 43px;
  top: 0;
  z-index: 8;
  right: 27px;
  left: 128px;
}

.nav_inner .navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
}

.nav_inner .navbar-brand img {
  width: 32px;
}

.nav_inner .deposit {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 30px;
}

.nav_inner .balance {
  display: flex;
  align-items: center;
  color: #45436b;
  font-size: 16px;
  font-weight: 600;
  gap: 7px;
  margin-right: 30px;
}

.nav_inner .notification .icon {
  border: 1px solid rgb(84 202 227 / 80%);
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav_inner .notification .icon.data::before {
  content: "";
  position: absolute;
  background: #000;
  width: 10px;
  height: 10px;
  background: #45436b;
  border-radius: 50%;
  right: 10px;
  top: 10px;
}

.nav_inner .notification .dropdown-toggle::after {
  display: none;
}

.nav_inner .notification .dropdown-toggle {
  text-decoration: none;
}

.nav_inner .notification .walletdrop {
  width: 230px;
  max-height: 250px;
  overflow-y: scroll;
}

.nav_inner .notification .dropdown-item {
  word-break: break-word;
  text-wrap: wrap;
  border-bottom: 1px solid #d7f4fb;
}

.nav_inner .notification .hd {
  font-weight: 600;
  text-align: center;
  cursor: unset;
}

.nav_inner .notification .clear {
  border: 0;
  text-align: center;
}

.nav_inner .notification li:first-child a,
.nav_inner .notification li:last-child a {
  color: #212529 !important;
  background: transparent !important;
}

.nav_inner .notification li:not(:first-child, :last-child) {
  margin: 8px;
}

.nav_inner .notification li:not(:first-child, :last-child) a {
  background: #f9f7ff !important;
  color: #212529 !important;
  border-radius: 5px;
  padding: 8px;
  border: 0;
}

.nav_inner .user_drp .dropdown-toggle {
  color: #45436b !important;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav_inner .user_drp .dropdown-toggle::after {
  background: url("./assets/images/down.svg") no-repeat center;
  width: 10px;
  height: 6px;
  border: 0;
}

.nav_inner .navbar_inner_right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.nav_inner .dropdown-menu[data-bs-popper] {
  left: unset !important;
  right: 0;
}

.primary_btn {
  border: 1px solid #1ed6fb !important;
  border-radius: 150px;
  box-shadow: none !important;
  background: linear-gradient(106deg, #67ace4, #137ed4, #137ed4, #67ace4);
  font-weight: 700;
  color: #fff;
  padding: 10px 30px;
}

.primary_btn_success {
  border: 1px solid #77d484 !important;
  border-radius: 150px;
  box-shadow: none !important;
  background: linear-gradient(106deg, #5db935, #05bb15, #1cb000, #0d7201);

  font-weight: 700;
  color: #fff;
  padding: 10px 30px;
}

.primary_btn:hover {
  transition: 0.5s background;
  background: linear-gradient(16deg, #67ace4, #137ed4, #67ace4);
}

.h2tag {
  font-size: 38px;
  font-weight: 800;
  margin-bottom: 25px;
  color: #07053a;
}

.h2tag span {
  background: linear-gradient(
    180deg,
    rgba(250, 176, 85, 1) 30%,
    rgba(250, 136, 129, 1) 58%,
    rgba(253, 105, 104, 1) 61%,
    rgba(254, 73, 140, 1) 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.para {
  font-size: 18px;
}

.h3tag {
  font-size: 36px;
  color: #0c053b;
  font-weight: 600;
}

.page_header {
  text-align: center;
  padding: 150px 0 50px;
  background: url("./assets/images/banbg.png") no-repeat center;
  background-size: 100%;
  position: relative;
  z-index: 1;
}

.page_header::before {
  content: "";
  position: absolute;
  background: url("./assets/images/banicons.png") no-repeat center;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: -1;
  opacity: 0;
  animation: fade 1s 1 forwards;
  animation-delay: 0.2s;
}

@keyframes fade {
  100% {
    opacity: 1;
  }
}

.page_header .box {
  position: relative;
}

.page_header .box .icons img {
  position: absolute;
}

.page_header h1 {
  font-weight: 800;
  font-size: 48px;
  color: #07053a;
  margin-bottom: 30px;
}

.page_header h1 span {
  background: linear-gradient(
    180deg,
    rgba(250, 176, 85, 1) 30%,
    rgba(250, 136, 129, 1) 55%,
    rgba(253, 105, 104, 1) 50%,
    rgba(254, 73, 140, 1) 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page_header p {
  margin-bottom: 30px;
  font-size: 24px;
}

.page_header .primary_btn {
  font-size: 28px;
}

.main_wrapper {
  /* margin-top: -140px; */
  position: relative;
  z-index: 1;
}

.why_sec {
  padding: 45px 0;
}

.why_sec .box {
  padding: 40px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 24px;
}

.why_sec .box::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.why_sec .box h5 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.why_sec .box button {
  border-radius: 150px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  padding: 10px 20px;
  color: #45436b;
  background: transparent;
}

.why_sec .box img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.why_sec .box:not(.rose) img {
  margin-right: -20px;
  margin-bottom: -10px;
}

.why_sec .box.sandal {
  border: 2px solid #fae293;
  background: linear-gradient(129deg, #fff3cc, #e4d5a1) !important;
}

.why_sec .box.rose {
  border: 2px solid #ffe3ec;
  background: linear-gradient(298deg, #fdd2e0, #ffebf2) !important;
}

.why_sec .box.green {
  border: 2px solid #d1fdf2;
  background: linear-gradient(136deg, #dafff6, #b6f6e8) !important;
}

.why_sec .box.violet {
  border: 2px solid #e5e4ff;
  background: linear-gradient(125deg, #ededff, #c2c1fd) !important;
}

.why_sec .box.sandal::before {
  background: url("./assets/images/w1bg.png") no-repeat right center;
}

.why_sec .box.rose::before {
  background: url("./assets/images/w2bg.png") no-repeat right center;
}

.why_sec .box.green::before {
  background: url("./assets/images/w3bg.png") no-repeat right center;
}

.why_sec .box.violet::before {
  background: url("./assets/images/w4bg.png") no-repeat right center;
}

.why_sec .box.sandal h5 {
  color: #ae8a14;
}

.why_sec .box.rose h5 {
  color: #c35679;
}

.why_sec .box.green h5 {
  color: #3aa68d;
}

.why_sec .box.violet h5 {
  color: #5d5aba;
}

.why_sec .box.sandal button {
  border: 1px solid #ae8a14;
}

.why_sec .box.rose button {
  border: 1px solid #c35579;
}

.why_sec .box.green button {
  border: 1px solid #3aa68d;
}

.why_sec .box.violet button {
  border: 1px solid #5d5aba;
}

.why_sec .box.sandal button:hover {
  background: #fff3cc;
}

.why_sec .box.rose button:hover {
  background: #fdd2e0;
}

.why_sec .box.green button:hover {
  background: #dafff6;
}

.why_sec .box.violet button:hover {
  background: #ededff;
}

.supporting {
  background: #0a063a url("./assets/images/supportbg.png") no-repeat center;
  overflow: hidden;
  margin: 45px 0;
}

.supporting h3 {
  color: #fcda24;
  margin-bottom: 20px;
}

.supporting .para {
  color: #fff;
  margin-bottom: 20px;
}

.supporting button {
  border: 1px solid #50ffd6;
  font-size: 20px;
  font-weight: 600;
  color: #50ffd6;
  border-radius: 100px;
  padding: 5px 20px;
  background: transparent;
}

.supporting button:hover {
  background: #0c1e5dad;
}

.supporting .img {
  position: relative;
  padding-top: 60px;
  text-align: center;
  z-index: 1;
}

.supporting .img::before {
  content: "";
  position: absolute;
  background: rgba(18, 161, 248, 50%);
  width: 100%;
  height: 100%;
  filter: blur(150px);
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
}

.suppliers {
  padding-top: 20px;
  overflow: hidden;
}

.suppliers .box {
  display: flex;
  align-items: center;
}

.suppliers .box.left {
  animation: slide-left 100s linear infinite;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.suppliers .box > div {
  width: 165px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b8fcf0;
  border-left: 0;
}

.suppliers .boxes {
  position: relative;
  margin-top: 170px;
  margin-bottom: 200px;
}

.suppliers .round {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  display: inline-block;
  width: fit-content;
  height: fit-content;
}

.suppliers .round > div {
  width: 320px;
  height: 320px;
  background: linear-gradient(-45deg, #66f7dd, #52e4ef);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 50%;
}

.suppliers .round > div::before {
  content: "";
  position: absolute;
  background: linear-gradient(-45deg, #66f7dd, #52e4ef);
  filter: blur(50px);
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.product {
  padding: 0px 0 40px;
  position: relative;
  z-index: 1;
}

.product::before {
  content: "";
  position: absolute;
  background: url("./assets/images/productbg.png") no-repeat center;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: -60%;
  z-index: -1;
}

.product .col-lg-6 {
  padding: 5px;
}

.product .box {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 5%);
  border: 1px solid #b8fcf0;
  background: #fff;
}

.product .box .icon {
  height: 100px;
}

.product .box h5 {
  font-size: 22px;
  font-weight: 700;
  color: #07053a;
  margin-bottom: 30px;
}

.product .box h4 {
  font-size: 26px;
  font-weight: 700;
  color: #2075e0;
  margin-bottom: 20px;
}

.product .box .primary_btn {
  font-weight: 600;
  white-space: nowrap;
}

.cta {
  padding: 40px 0;
  position: relative;
  z-index: 1;
}

.cta .box {
  background: #116ac5 url("./assets/images/ctabg.png") no-repeat center;
  box-shadow: inset 10px 10px 50px 10px rgba(255, 255, 255, 16%);
  border-radius: 20px;
  padding: 40px;
}

.cta .box h4 {
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
  color: #fff;
}

.cta .box h4 span {
  color: #fde324;
}

.cta .box button {
  color: #fde324;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #fde324;
  background: transparent;
  border-radius: 150px;
  padding: 10px 25px;
}

.cta .box button:hover {
  background: #1d73c9f3;
}

.cta .box .icon {
  margin-left: -40px;
}

.faq {
  padding: 50px 0;
}

.faq .accordion {
  max-width: 85%;
  margin: 0 auto;
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #b8fcf0;
  background: transparent;
  border-radius: 0;
  padding: 35px 0;
  position: relative;
  background-color: #fff;
  color: #45436b !important;
}

.faq .accordion-item:nth-last-child(1) {
  border: 0;
}

.faq .accordion-item button {
  color: #07053a;
  font-weight: 700;
  font-size: 18px;
  background: transparent;
  box-shadow: none !important;
  padding: 0;
  padding-left: 62px;
}

.faq .para {
  color: #000000;
  margin-bottom: 0;
}

.faq .accordion-body {
  padding: 15px 0 0;
  padding-left: 62px;
}

.faq .accordion-body p {
  margin-bottom: 0;
}

.faq .accordion-button::after {
  background: url("./assets/images/plus.svg") no-repeat center;
  width: 40px;
  height: 40px;
  transition: 0.5s;
  background-size: contain;
  left: 0;
  position: absolute;
}

.faq [aria-expanded="true"].accordion-button::after {
  background: url("./assets/images/close.svg") no-repeat center;
}

.slide_sec {
  padding: 40px 0;
  position: relative;
  z-index: 1;
}

.slide_sec .link {
  color: #159cd6;
  font-size: 18px;
  font-weight: 500;
}

.slide_sec .bgbox {
  position: relative;
  z-index: 1;
}

.slide_sec .bgbox::before {
  content: "";
  position: absolute;
  background: url("./assets/images/slidebg.png") no-repeat center;
  right: calc(0% - 121px);
  z-index: -3;
  width: 249px;
  height: 317px;
  max-width: 100%;
  bottom: 0;
}

.slide_sec .box {
  border-radius: 20px;
  border: 1px solid #b8fcf0;
  padding: 60px;
  padding-left: 100px;
  background: #fff;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 0.05);
}

.slide_sec .box .para {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  margin-bottom: 30px;
}

.slide_sec .box .usr_box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.slide_sec .box .usr_box .user img {
  height: 70px;
  border-radius: 50%;
}

.slide_sec .box .usr_box h5 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0;
  line-height: normal;
}

.slide_sec .slick-dots {
  right: 0;
  width: fit-content;
  bottom: 32px;
  background: #fff;
}

.slide_sec .slick-dots li {
  margin: 0 2px;
  height: 5px;
}

.slide_sec .slick-dots li button {
  height: 4px;
  padding: 0;
}

.slide_sec .slick-dots li button:before {
  content: "";
  background: #c7d9fc;
  border-radius: 50px;
  width: 20px;
  height: 4px;
  opacity: 1 !important;
  top: -11px;
}

.slide_sec .slick-dots li.slick-active button:before {
  background: #6388d2;
}

.slide_sec .slick-arrow {
  background: url("./assets/images/slick_arw.svg") no-repeat center #fff !important;
  width: 26px;
  height: 26px;
  transform: rotate(0deg);
  background-size: contain;
  right: 46px;
  left: unset;
  bottom: 5px;
  top: unset;
  z-index: 10;
}

.slide_sec .slick-next {
  transform: rotate(180deg);
  right: 0;
}

.slide_sec .slick-arrow::before {
  display: none;
}

footer.footer {
  background: #0b0640;
  padding-top: 50px;
}

footer.footer .para {
  color: #999aa8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
}

.footer .right_box h5 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.footer .right_box a {
  display: block;
  margin-bottom: 10px;
  color: #b1abf2 !important;
  text-decoration: none !important;
}

.footer_panel {
  border-bottom: 1px solid #1f195c;
  margin-bottom: 10px;
  padding-bottom: 100px;
  color: #fff !important;
}

.footer_social_links {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0;
  list-style-type: none;
}

.footer_social_links a {
  border-radius: 32px;
  background: rgba(255, 255, 255, 28%);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0b0640 !important;
  font-size: 18px;
  text-decoration: none !important;
  transition: 0.5s ease-out;
}

.footer_social_links a:hover {
  background: #fbe332;
  color: #0b0640 !important;
}

.footer .blw {
  color: #e4e4e7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  padding-bottom: 20px;
  padding-top: 20px;
}

.footer .blw a {
  color: #b1abf2;
  text-decoration: none !important;
}

.footer .blw .link {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer .blw .link li {
  position: relative;
}

.footer .blw .link li:not(:last-child):before {
  content: "";
  position: absolute;
  background: #1f195c;
  width: 1px;
  height: 90%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: -12px;
}

.footer p .atag {
  color: #fbd21f;
}

.launch {
  flex-wrap: nowrap;
}

.page_wrapper {
  display: flex;
  gap: 27px;
  min-height: 100vh;
  padding: 25px 35px 65px;
  background: #f9f9f9;
}

.right_wrapper {
  margin-top: 100px;
}

.sidebar {
  outline: 1px solid #ebebeb;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 5%);
  background: #fff url("./assets/images/bgbot.svg") no-repeat center bottom;
  border-radius: 20px;
  padding: 15px;
  width: 240px;
  min-height: calc(100vh - 50px);
  outline-offset: -1px;
  position: fixed;
  z-index: 10;
  transition: 0.5s ease-out;
}

.sidebar.open + div {
  margin-left: 267px;
  width: calc(100% - 267px) !important;
  transition: 0.5s ease-out;
}

.sidebar.close + div {
  transition: 0.5s ease-out;
  margin-left: 94px;
  width: calc(100% - 94px) !important;
}

.sidebar.close {
  transition: 0.5s ease-out;
  width: 70px;
}

.sidebar.close .menu_icon {
  right: 3px;
  transition: 0.5s ease-out;
}

.sidebar.close .navbar-brand {
  opacity: 0;
  transition: 0.5s ease-out;
  width: 0;
}

.sidebar.close .name .round {
  margin-left: -2px;
  transition: 0.5s ease-out;
}

.sidebar .menu_icon {
  position: absolute;
  right: -76px;
  z-index: 10;
  cursor: pointer;
  border-radius: 50%;
  top: -28px;
}

.menu_icon_relative {
  position: relative;
  top: -18px;
}

.logo_inner {
  width: 170px;
  margin-left: 24px;
}

.sidebar .name {
  border: 1px solid #f1df20;
  border-radius: 120px;
  background: linear-gradient(135deg, #fcec7a, #fae123, #fcec7a);
  display: flex;
  align-items: center;
  padding: 8px 10px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  color: #07053a;
  margin: 35px 0;
}

.sidebar .name .round {
  background: #ffef7a;
  color: #000;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sidebar .name .flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
}

.sidebar .menus {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.sidebar .menus a {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #45436b;
  font-weight: 600;
  padding: 8px 17px;
  padding-left: 7px;
  border: 1px solid transparent;
  margin-bottom: 10px;
  border-radius: 120px;
  text-decoration: none;
  white-space: nowrap;
  min-width: 19px;
}

.sidebar .menus a:hover {
  transition: 0.5s ease-out;
  color: #fff;
  background: linear-gradient(
    135deg,
    #f8f8ff -30%,
    #0c053b 29%,
    #0c053b 70%,
    #0c053b 76%,
    #f9f8ff 150%
  );
  border: 1px solid #0c053b;
}

.sidebar .menus a:hover img {
  filter: brightness(0) invert(1);
}

.sidebar .menus a img {
  min-width: 25px;
  height: 20px;
}

.dashboard {
  width: 100%;
}

.dashboard .h_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
}

.dashboard .h_flx .right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.dashboard .h_flx .curr {
  font-size: 14px;
  color: #45436b;
  text-wrap: nowrap;
}

.dashboard .abv_box {
  display: flex;
  gap: 35px;
}

.dashboard .box {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 5%);
  padding: 20px 25px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.dashboard .box .img_box {
  width: 70px;
  height: 70px;
  background-color: #0c053b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .box p {
  color: #45436b;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 600;
}

.dashboard .box h5 {
  color: #0c053b;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

.dashboard .box button {
  padding: 5px 20px;
  font-size: 14px;
}

.dashboard .overview_box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.dashboard .overview_box li {
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: space-between;
}

.dashboard .overview_box li .dark {
  font-weight: 600;
}

.dashboard .overview_box_chg li p {
  min-width: 130px;
  font-size: 15px;
}

.dashboard .overview_box_chg li .small {
  font-size: 12px;
  text-align: right;
}

.dashboard .overview_box_chg .progress {
  border-radius: 0;
}

.dashboard .overview_box_chg .progress-bar {
  font-size: 10px;
}

.dashboard .chart_flx {
  display: flex;
  margin: 35px 0;
  gap: 35px;
}

.gap-35 {
  gap: 35px;
}

.dashboard .chart_flx .box.first {
  min-width: 74.4%;
}

.dashboard .chart_box {
  height: 100%;
}

.dashboard .dash_blw_flx {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.custom_table .rdt_TableCol {
  font-weight: 500;
  font-size: 14px;
  color: #45436b;
}

.custom_table .rdt_TableCell {
  font-weight: 400;
  font-size: 14px;
  color: #45436b;
}

.custom_table .rdt_TableRow {
  border-bottom-color: #ededed !important;
}

.custom_table_chg *::-webkit-scrollbar {
  width: 6px;
  height: 9px;
  border-radius: 20px;
}

.custom_table_chg *::-webkit-scrollbar-track {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

.custom_table_chg *::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #45436b;
}

.custom_table_chg .rdt_TableHeadRow {
  background-color: #f9f9f9 !important;
}

.custom_table_chg .rdt_TableCol {
  font-weight: 400;
  font-size: 14px;
  color: #45436b;
}

.custom_table_chg .rdt_TableCell {
  font-weight: 600;
  font-size: 14px;
  color: #45436b;
  background-color: #f9f9f9 !important;
}

.custom_table_chg .rdt_TableCell div {
  white-space: wrap !important;
}

.custom_table_chg .rdt_TableRow {
  border-bottom-color: #ededed;
  background-color: #f9f9f9 !important;
  min-height: 58px;
}

.custom_table_chg .rdt_TableRow input[type="checkbox"] {
  appearance: none !important;
  border: 1px solid #a7efff !important;
  border-radius: 3px;
  padding: 7px !important;
}

.custom_table_chg .rdt_TableRow input[type="checkbox"]:checked {
  background: url("./assets/images/check.svg") no-repeat center !important;
  background-size: 10px !important;
}

.custom_table_chg .rdt_Pagination {
  background-color: #f9f9f9 !important;
}

.custom_table_chg .rdt_Pagination button {
  background: #45436b !important;
  fill: #fff;
  margin: 0 5px;
}

.custom_table_chg .rdt_Pagination [aria-disabled="true"] svg {
  fill: #ffffff85;
}

.custom_table_chg .rdt_Pagination select {
  outline: 0 !important;
}

.import_box .custom_table_chg .rdt_TableRow,
.import_box .custom_table_chg .rdt_TableCell,
.import_box .custom_table_chg .rdt_Pagination,
.import_box .custom_table_chg .rdt_TableHeadRow {
  background-color: #fff !important;
}

.import_box .custom_table_chg .rdt_TableCol {
  font-weight: 600;
  font-size: 14px;
  color: #45436b;
}

.import_box .custom_table_chg .rdt_TableCell {
  font-weight: 400;
}

.import_box h6 {
  font-weight: 600;
  font-size: 18px;
  color: #0c053b;
  border-bottom: 1px solid #ededed;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.import_box h6 + p {
  color: #5c5a7e;
}

.btn-close {
  box-shadow: none !important;
}

.custom_modal button[aria-label="Close"] {
  background: url("./assets/images/close_btn.svg") no-repeat center;
  opacity: 1;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  background-size: contain;
}

.custom_modal button[aria-label="Close"]:hover {
  opacity: 0.8;
}

.custom_modal .modal-content {
  padding: 24px;
}

.custom_modal .modal-header {
  border-bottom: 1px solid #ededed;
  padding: 0;
  padding-bottom: 10px;
  justify-content: space-between;
}

.custom_modal .modal-body {
  padding: 0;
}

/* input  */
.input_box .form-control {
  border: 1px solid;
  border-color: rgba(206, 243, 251, 0.8) !important;
  border-radius: 125px;
  background: #ffffff;
  padding: 10px;
  color: #000 !important;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.input_box .form-control::placeholder {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #45436b !important;
}

.input_box input:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  background: rgba(255, 0, 0, 0) !important;
  transition: background-color 5000s ease-in-out 0s;
}

.input_box .input-group-text {
  border-radius: 120px 0 0 120px;
  background: #fff;
  border: 1px solid;
  border-color: rgba(206, 243, 251, 0.8) !important;
  border-right: 0 !important;
}

.input_box .input-group-text + .form-control {
  border-left: 0 !important;
}

.input_box .form-control + .input-group-text {
  border: 1px solid !important;
  border-color: rgba(206, 243, 251, 0.8) !important;
  border-left: 0 !important;
  border-radius: 0 120px 120px 0;
}

.input_box .blue {
  color: #0f4ba7 !important;
}

/* input ends */
.drp_dwn .dropdown-toggle {
  background: transparent;
  border: 1px solid rgba(206, 243, 251, 0.8) !important;
  border-radius: 125px;
  background: #ffffff !important;
  padding: 10px 30px;
  color: #45436b !important;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  box-shadow: none !important;
  gap: 5px;
}

.drp_dwn .dropdown-toggle::after {
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: url("./assets/images/down.svg") no-repeat center;
  border: 0;
  width: 10px;
  height: 6px;
}

.drp_dwn [aria-expanded="true"].dropdown-toggle::after {
  transform: rotate(180deg);
}

.drp_dwn .dropdown-item:hover {
  background-color: #e7e9eb !important;
  color: #000 !important;
}

.drp_dwn_hgt .dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

/* drp_dwn ends  */

.modal_box {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal_box .main_name {
  font-weight: 600;
  font-size: 18px;
  color: #0c053b;
}

.modal_box .main_box {
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  padding-top: 10px;
  margin-top: 10px;
}

.modal_box .main_box .round {
  background: #d8f6fc;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #45436b;
  font-weight: 800;
  font-size: 22px;
  border-radius: 50%;
}

.modal_box .main_box .d-flex {
  margin-bottom: -6px;
}

.modal_box .main_box p {
  color: #45436b;
  font-weight: 600;
  margin-bottom: 0;
}

.modal_box .main_box .box {
  border-radius: 5px;
  padding: 1px 5px;
  background: #f5f5f5;
  text-align: center;
}

.modal_box .main_box .dropdown-toggle {
  width: 5px;
}

.modal_box .main_box .dropdown-toggle::after {
  display: none;
}

.modal_box .main_box .dropdown-item {
  font-weight: 500;
  font-size: 14px;
  color: #45436b;
  padding: 10px;
  display: flex;
  align-items: center;
}

.modal_box .main_box .dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.modal_box .main_box .dropdown-menu {
  border: 1px solid #b8fcf0;
  border-radius: 10px;
}

.box_bdr {
  border: 1px solid #ededed;
  padding: 8px;
}

.add {
  border-top: 1px solid #ededed;
  padding-top: 15px;
  text-align: right;
}

.add button {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
}

.store_box {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
}

.store_box .active {
  border-color: #07053a;
}

.store_box > div {
  box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  border: 1px solid #b8fcf0;
  border-radius: 20px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan_box h3 {
  color: #0c053b;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}

.plan_box .grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  display: flex;
}

.plan_box .box {
  border: 1px solid rgba(206, 243, 251, 0.8);
  border-radius: 10px;
  text-align: center;
  background: #fff;
  position: relative;
  width: 256px;
}

.plan_box .abv_box {
  border-radius: 10px 10px;
  padding: 40px 20px;
}

.plan_box .box.rose .abv_box {
  border: 2px solid #ffe2ec;
  background: linear-gradient(170deg, #ffeaf1, #fdd2e0);
}

/* .plan_box .box.green .abv_box {
  border: 2px solid #d1fdf2;

  background: linear-gradient(170deg, #d9fff6, #b5f6e7);
} */

.plan_box .box.blue .abv_box {
  border: 2px solid #e5e4ff;
  background: linear-gradient(170deg, #ededff, #c3c1fd);
}

.plan_box .abv_box.yellow {
  background: url("./assets/images/c1.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.green {
  background: url("./assets/images/c2.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.black {
  background: url("./assets/images/c3.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.grey {
  background: url("./assets/images/c4.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.lightgrey {
  background: url("./assets/images/c5.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.violet {
  background: url("./assets/images/c6.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.BlueBlack {
  background: url("./assets/images/6.jpg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.YellowGreeen {
  background: url("./assets/images/7.png") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.Orange {
  background: url("./assets/images/3.jpg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.LightGreen {
  background: url("./assets/images/2.jpg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.Purple {
  background: url("./assets/images/5.jpg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.Blue {
  background: url("./assets/images/1.jpg") no-repeat center !important;
  background-size: cover !important;
}

.plan_box .abv_box.black p,
.plan_box .abv_box.black span,
.plan_box .abv_box.violet p,
.plan_box .abv_box.violet span {
  color: #fff;
}

.plan_box .abv_box.black .dropdown-toggle::after,
.plan_box .abv_box.violet .dropdown-toggle::after {
  border-top: 0.7em solid #fff;
}

.plan_box .abv_box.yellow p,
.plan_box .abv_box.yellow span {
  color: #000;
}

/* 
.plan_box .abv_box::before{
  content: "";
  position: absolute;
  background: #0000006c;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
} */
.plan_box .abv_box p {
  font-weight: 500;
  font-size: 16px;
  color: #0c053b;
  margin-bottom: 0px;
  word-break: break-word;
}

.plan_box .abv_box h2 {
  font-weight: 800;
  font-size: 32px;
  color: #ae8a14;
  margin-bottom: 0;
}

.plan_box .abv_box span {
  font-weight: 500;
  font-size: 13px;
  color: #45436b;
}

.plan_box .abv_box .dropdown-toggle {
  background-color: transparent;
  border: 0;
  width: 100%;
}

.plan_box .abv_box .dropdown-toggle::after {
  margin-left: 0.355em;
  vertical-align: 0.155em;
  border-top: 0.7em solid black;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  position: absolute;
  right: 0;
  top: 15%;
  margin: 10px;
}

.plan_box .abv_box .dropdown-menu {
  left: -45px !important;
  height: 200px;
  overflow-y: auto;
}

.plan_box .abv_box .dropdown-item {
  font-size: 12px;
}

.plan_box .abv_box .dropdown-item:hover {
  background-color: #0000000f !important;
  color: #000 !important;
}

.plan_box .blw_box {
  padding: 20px;
  left: 0;
  right: 0;
  z-index: 2;
  border: 1px solid #dfdfdf;
  margin-bottom: 0px;
  margin-top: -10px;
}

.plan_box .blw_box.show {
  display: block;
}

.plan_box .blw_box.hide {
  display: none;
}

.plan_box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.plan_box li {
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
}

.plan_box button {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  padding: 7px;
  width: 170px;
}

.sel_flx {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sel_flx > div {
  border: 1px solid rgba(206, 243, 251, 0.8);
  border-radius: 10px;
  min-width: 150px;
  height: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 13px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #45436b;
  padding: 10px;
  cursor: pointer;
}

.sel_flx > div.active {
  border: 1px solid rgba(12, 5, 59, 0.2);
  background: linear-gradient(106deg, #716d8c, #0c053b, #0c053b, #716d8c);
  color: #fff;
}

.sel_flx_chg {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  gap: 0;
}

.sel_flx_chg > div {
  flex-direction: row;
  justify-content: flex-start;
  border: 1px solid rgb(225 225 225 / 80%);
  height: 100% !important;
  display: grid;
  grid-template-columns: 50px 1fr;
  text-align: left;
  border-radius: 0;
  padding: 5px;
}

.sel_flx_chg > div img {
  max-height: 50px;
  width: auto !important;
  margin: 0 auto;
}

.categories .hflx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.categories .hflx h6 {
  font-weight: 600;
  font-size: 20px;
  color: #0c053b;
  margin-bottom: 0;
}

.categories .hflx .flx {
  display: flex;
  gap: 25px;
  align-items: center;
}

.categories .box {
  border: 1px solid rgba(206, 243, 251, 0.8);
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 24px;
  width: 100%;
}

.categories .listView .box {
  display: flex;
  justify-content: flex-start;
}

.product-list-detail-box {
  margin-left: 30px;
}
.categories .listView .product-list-detail-box {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: calc(100% - 75px);
}
.categories .img_box {
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories .listView .img_box {
  height: auto;
}
.categories .listView .list-3 {
  width: 36%;
}
.categories .img_box img {
  max-height: 230px;
}

.categories .listView .img_box img {
  width: 75px;
}

.categories .box .bach {
  background: linear-gradient(
    106deg,
    #faea79,
    #fae123,
    #fae123,
    #faea79
  ) !important;
  border: 1px solid #f1df20;
  border-radius: 60px;
  color: #0c053b;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 8px;
  padding: 0 25px;
}

.categories .box p {
  font-weight: 400;
  font-size: 14px;
  color: #45436b;
  margin-bottom: 20px;
  height: 145px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.categories .listView .box p {
  height: auto;
}

.categories .listView .box .primary_btn {
  max-width: 250px;
}

.categories .box h5 {
  font-weight: 700;
  font-size: 16px;
  color: #0c053b;
  margin-bottom: 10px;
  word-break: break-word;
}

.categories .box .detail {
  background: #d2f4fd;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #45436b;
  padding: 1px 6px;
  margin-bottom: 25px;
  display: inline-block;
}

.categories .box .detail img {
  margin-right: 5px;
}

.categories_chg .img_box {
  height: 150px;
}

.categories_chg .img_box img {
  max-height: 130px;
}

.listGridButton .btn-primary {
  display: flex;
  background: linear-gradient(
    106deg,
    #67ace4,
    #137ed4,
    #137ed4,
    #67ace4
  ) !important;
  border: 1px solid #1ed6fb !important;
  opacity: 0.6;
  transition: 0.25s;
}

.listGridButton .btn-primary.active,
.listGridButton .btn-primary:hover {
  opacity: 1;
}

.listGridButton .btn-primary svg {
  min-width: 20px;
  margin-right: 7px;
  color: #ffffff;
  fill: #ffffff;
}

.login {
  display: flex;
  min-height: 100vh;
}

.login h2 {
  font-weight: 700;
  font-size: 36px;
  color: #fff;
  margin-bottom: 30px;
}

.login h2 span {
  color: #00e0ba;
}

.login .left {
  background: #0a063a url("./assets/images/login_bg.png") no-repeat center;
  min-height: 100vh;
  width: 45%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login label {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
}

.login .form-control {
  border-color: #fff !important;
  border-radius: 10px;
}

.login .input-group-text {
  border-radius: 10px 0 0 10px;
  border-color: #fff !important;
}

.login .form-control + .input-group-text {
  border-color: #fff !important;
  border-radius: 0 10px 10px 0;
}

.login .right {
  min-height: 100vh;
  width: 55%;
}

.login .form-check {
  display: flex;
  gap: 8px;
}

.login .form-check-input {
  padding: 10px;
  box-shadow: none !important;
}

.login .link {
  font-size: 18px;
  color: #159cd6 !important;
  display: inline-block;
  text-decoration: none !important;
}

.login .signup {
  color: #fff;
  font-size: 20px;
}

.login .signup a {
  color: #159cd6;
  text-decoration: none !important;
}

.login .para {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #45436b;
}

.login .right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .right > div {
  width: 65%;
  margin: auto;
  height: fit-content;
}

.login button {
  background: linear-gradient(
    164deg,
    #12529b,
    #137ed4,
    #137ed4,
    #12529b
  ) !important;
}

.pdt_det .right_img .slick-list {
  background-color: #fff;
  border: 1px solid rgba(206, 243, 251, 0.8);
  border-radius: 10px;
  margin-bottom: 15px;
  overflow: hidden;
}

.pdt_det .right_img .box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 540px;
  padding: 10px;
}

.pdt_det .right_img .slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: unset;
}

.pdt_det .right_img .slick-dots li {
  border: 1px solid rgba(206, 243, 251, 0.8);
  border-radius: 10px;
  width: 66px;
  height: 66px;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
}

.pdt_det .right_img .slick-dots li a {
  padding: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdt_det .sub_tit {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  font-weight: 500;
  font-size: 14px;
  color: #8c8c8c;
  border-bottom: 1px solid #ededed;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.pdt_det .sub_tit span {
  font-weight: 500;
  font-size: 14px;
  color: #45436b;
}

.pdt_det .item_box .flx {
  display: flex;
  align-items: center;
  gap: 100px;
}

.pdt_det .item_box span {
  font-weight: 500;
  font-size: 14px;
  color: #8c8c8c;
}

.pdt_det .item_box h3 {
  font-weight: 700;
}

.pdt_det .item_box button {
  width: 300px;
}

.pdt_det .item_box .shipping {
  font-weight: 600;
  font-size: 16px;
  color: #0c053b;
}

.custom_tab .nav {
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}

.custom_tab .nav-link {
  font-weight: 600;
  font-size: 16px;
  color: #45436b;
  padding-left: 0;
  padding-right: 0px;
  margin-right: 25px;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
}

.custom_tab .nav-link.active {
  color: #0c053b;
  border-bottom: 2px solid #fae123;
}

.custom_table .table {
  width: 100%;
  background: #f5f5f5;
}

.custom_table th {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #45436b;
}

.custom_table td,
.custom_table th {
  padding: 13px;
  padding-left: 35px;
  white-space: nowrap;
  border: 1px solid #fff;
}

.dropdown-menu {
  border-color: rgba(206, 243, 251, 0.8);
}

.cursor-pointer {
  cursor: pointer;
}

.market_btn {
  border: 1px solid #f1df20 !important;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  color: #45436b;
  padding: 5px 25px;
  background: linear-gradient(
    106deg,
    #faea79,
    #fae123,
    #fae123,
    #faea79
  ) !important;
}

.market_btn span {
  font-weight: 700;
  /* color: #fff; */
  color: #0c053b;
}

.atag {
  font-weight: 500;
  font-size: 14px;
  color: #159cd6 !important;
  text-decoration: none !important;
}

.add_prd {
  border: 1px solid #f1df20;
  border-radius: 120px;
  background: linear-gradient(135deg, #fcec7a, #fae123, #fcec7a);
  display: flex;
  align-items: center;
  padding: 8px 10px;
  padding-right: 20px;
  font-size: 14px;
  font-weight: 600;
  gap: 8px;
  color: #07053a;
  margin: 35px 0;
}

.reg {
  font-weight: 400 !important;
}

.mem {
  font-weight: 500 !important;
}

.sem {
  font-weight: 600 !important;
}

.bol {
  font-weight: 700 !important;
}

.sm_txt {
  font-size: 14px;
}

.dark {
  color: #0c053b;
}

.h3tag.bdr {
  border-bottom: 1px solid #ededed;
  padding-bottom: 15px;
}

.custom_date .custom_input {
  border: 1px solid #cef3fb;
  color: #45436b;
  font-size: 14px;
  background: #fff;
  padding: 10px 25px;
  border-radius: 120px;
  display: flex;
  align-items: center;
}

.custom_date .custom_input .date {
  margin-left: 20px;
  margin-right: 35px;
}

.custom_date .custom_input .uparw {
  transform: rotate(180deg);
}

.react-datepicker {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #e9e9e9 !important;
}

.react-datepicker__header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e9e9e9 !important;
  color: #000000 !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: #fff !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000 !important;
}

.react-datepicker__time-container {
  border-left: 1px solid #e9e9e9 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #e9e9e9 !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #dfdfdf !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: #fff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #1b62aa !important;
  color: #fff !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #7ec6ef !important;
  color: #111 !important;
}

.lgd {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.lgd li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #45436b;
}

.lgd .cir {
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
}

.lgd .cir.blue {
  background: #19bffb;
}

.lgd .cir.green {
  background: #35e3bf;
}

.lgd .cir.ylw {
  background: #fbb821;
}

.custom_faq {
  border-radius: 20px;
  background: #fff;
  padding: 24px;
}

.custom_faq h5 {
  color: #0c053b;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.custom_faq .accordion-item {
  border: 0;
}

.custom_faq .accordion-button {
  color: #707070;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}

.custom_faq .accordion-button::after {
  /* background-image: url("./assets/images/right_arw.png"); */
  width: 7px;
  height: 11px;
  background-size: contain;
}

.custom_faq [aria-expanded="true"]::after {
  transform: rotate(90deg) !important;
}

.custom_faq .accordion-item.active {
  background: #f6f8fa;
  border-radius: 10px;
}

.custom_faq .accordion-item.active .accordion-button {
  color: #1d1d1d;
}

.custom_faq .accordion-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

/* Styling for chat messages */
.chat_container {
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  overflow: hidden;
}

.chat_messages {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message.admin {
  align-items: flex-end;
}

.message_text {
  background-color: #f7f7f7;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 75%;
  font-size: 14px;
}

.message_time {
  font-size: 12px;
  color: var(--text_grey);
  margin-top: 5px;
}

.chat_input {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.chat_input input {
  border-radius: 5px;
  margin-right: 10px;
}

.chat_input button {
  cursor: pointer;
  transition: background-color 0.2s;
}
.chat_input .file_icon {
  position: relative;
  border-radius: 50%;
  border: 1px solid #a5a5a5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: -49px;
  color: #666d74;
  font-size: 14px;
}
.chat_input .file_icon input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  opacity: 0;
}
.kyc_box {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 5px rgba(0, 0, 0, 5%);
  padding: 20px 25px;
}

.product_slide .slick-list {
  max-width: 98%;
  margin: 0 auto;
}

.product_slide .slick-track > div > div {
  margin: 10px;
}

.product_slide .slick-arrow {
  background-image: url("./assets/images/left_arw.svg") !important;
  width: 5px;
  height: 22px;
  left: 2px;
  opacity: 1;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 8px;
  transform: rotate(0deg);
}

.product_slide .slick-next {
  right: 2px;
  left: unset;
  transform: rotate(180deg);
}

.product_slide .slick-arrow::before {
  display: none;
}

.product_slide .slick-arrow:hover {
  background-image: url("./assets/images/left_arw.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
}
.scrll > div > div:nth-child(3) {
  width: 0 !important;
}
.grey_b {
  color: #5e5b89;
}
.page_width {
  min-width: 100%;
}
.adr {
  font-size: 15px;
}

.common_page {
  padding: 150px 0;
  overflow: hidden;
}

.common_page tbody,
.common_page td,
.common_page tfoot,
.common_page th,
.common_page thead,
.common_page tr {
  border-width: 1px;
  padding: 7px;
  vertical-align: middle;
}
.common_page table p {
  margin-bottom: 0;
}
.common_page h2 {
  font-size: 28px;
}
.common_page .box {
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}
.common_page .box .img_box {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.common_page .box .img_box img {
  border-radius: 50%;
}
.whatsapp_btn {
  color: #ffffff;
  background: #25d366;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

@media (min-width: 1550px) {
  .page_header {
    background-size: 100% 100%;
  }
}

@media (max-width: 1399px) {
  .dashboard .abv_box,
  .dashboard .chart_flx {
    gap: 15px;
  }

  .plan_box .grid {
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
@media (max-width: 1500px) {
  .dashboard .abv_box .box {
    padding: 20px 15px;
  }
}
@media (max-width: 1300px) {
  .dashboard .abv_box,
  .dashboard .chart_flx {
    flex-direction: column;
  }

  .custom_date .custom_input .date {
    margin: 0 10px;
  }

  .dashboard .box button {
    padding: 5px 15px;
  }

  .custom_date .custom_input {
    padding: 8px 15px;
  }

  .gap-35 {
    gap: 15px;
  }
  img.brand_logo {
    width: 150px;
  }
  .offcanvas .links li a {
    padding: 10px 15px;
  }
  .offcanvas .links {
    padding: 12px 20px;
  }
}

@media (max-width: 1199px) {
  .h2tag {
    font-size: 32px;
  }

  .h3tag {
    font-size: 30px;
  }

  .login .para {
    font-size: 18px;
  }

  .custom-body-class {
    position: relative;
  }

  .custom-body-class::before {
    content: "";
    background-color: #000000ab;
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 10;
    position: absolute;
    min-height: 100vh;
  }

  .page_wrapper {
    padding: 25px 12px 65px;
  }

  .nav_inner {
    left: 0px;
    width: calc(100% - 0px);
    right: 0px;
    padding: 15px 0;
  }

  .sidebar {
    left: 10px;
    top: 10px;
    min-height: calc(100vh - 20px);
  }

  .sidebar.close {
    transition: 0.5s ease-out;
    left: -70px;
  }

  .sidebar .menu_icon {
    top: -42px;
  }

  .sidebar.close .menu_icon {
    right: -70px;
    transition: 0.5s ease-out;
    width: 47px;
  }

  .sidebar.close + div,
  .sidebar.open + div {
    margin-left: 0px;
    width: 100% !important;
  }

  .page_header h1 {
    font-size: 38px;
  }

  .page_header p {
    font-size: 20px;
  }

  .page_header .primary_btn {
    font-size: 18px;
  }

  .offcanvas {
    background: #ffffff;
  }

  .offcanvas .links {
    flex-direction: column;
    background: transparent;
    border: 0;
  }

  .offcanvas .links li {
    margin: 10px 0;
    border-radius: 120px;
  }

  .navbar_right {
    flex-direction: column;
  }

  .why_sec .box img {
    width: 40%;
  }

  .slide_sec .bgbox::before {
    right: calc(0% - 90px);
  }

  .slide_sec .box .para {
    font-size: 18px;
    line-height: unset;
  }

  .suppliers .round > div {
    width: 250px;
    height: 250px;
  }

  .suppliers .round > div img {
    max-width: 65%;
  }

  .suppliers .boxes {
    margin-top: 120px;
    margin-bottom: 150px;
  }

  .suppliers .box > div {
    width: 145px;
    height: 120px;
  }

  .suppliers .box > div img {
    max-width: 80%;
  }

  .product .box {
    padding: 30px;
  }

  .product .box h4 {
    font-size: 24px;
  }

  .dashboard .dash_blw_flx {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 991px) {
  .para {
    font-size: 16px;
  }
  .common_page .box {
    grid-template-columns: unset;
  }
  .react-datepicker-popper {
    left: 60px !important;
  }
  .pdt_det .right_img .box {
    height: 350px;
  }

  .pdt_det .right_img .slick-dots li {
    height: 50px;
    border-radius: 7px;
  }

  .pdt_det .right_img .slick-dots li a {
    padding: 2px;
  }

  .pdt_det .right_img .slick-dots {
    justify-content: center;
  }

  .login h2 {
    font-size: 32px;
  }

  .login .left {
    width: 100%;
  }

  .login label,
  .login .link,
  .login .signup {
    font-size: 16px;
  }

  .login label {
    margin-top: 3px;
  }

  .login .w-75 {
    width: 94% !important;
  }

  .categories .hflx {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .categories .hflx .flx {
    align-items: flex-start;
    gap: 15px;
    flex-direction: column;
  }

  .dashboard .h_flx {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .dashboard .abv_box,
  .dashboard .chart_flx {
    flex-direction: column;
  }

  .dashboard .h_flx .right {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .launch {
    flex-wrap: wrap;
    text-align: center;
  }

  .launch > div {
    width: 100% !important;
  }

  .launch .w-50 {
    text-align: center !important;
  }

  .why_sec .box {
    text-align: center;
  }

  .why_sec .box img {
    position: unset;
    margin-top: -50px;
    margin-right: -40px;
    display: none;
  }

  .why_sec .box:not(.rose) img {
    margin-right: -55px;
    margin-bottom: -10px;
  }

  .supporting {
    padding-top: 40px;
    text-align: center;
  }

  .slide_sec .bgbox::before {
    display: none;
  }

  .cta .box h4,
  .cta .box .col-lg-4 {
    text-align: center;
  }

  .cta .box .icon {
    max-width: 50%;
    margin-bottom: 10px;
  }

  .slide_sec .h2tag,
  .slide_sec .h2tag + div,
  .slide_sec .link {
    text-align: center;
  }

  .suppliers .round > div {
    width: 170px;
    height: 170px;
  }

  .suppliers .box > div {
    width: 115px;
    height: 90px;
    padding: 30px;
  }

  .footer .blw {
    flex-direction: column;
    text-align: center;
  }

  .footer_panel {
    padding-bottom: 10px;
    text-align: center;
  }

  .footer .right_box a:last-child {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .h2tag {
    font-size: 28px;
  }

  .h3tag {
    font-size: 26px;
  }

  .sel_flx > div {
    height: 110px;
  }

  .sel_flx > div img {
    width: 20%;
  }

  .dashboard .box h5 {
    font-size: 22px;
  }

  .dashboard .box .img_box {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .footer .blw .link {
    flex-direction: column;
  }

  .footer .blw .link li:not(:last-child):before {
    display: none;
  }

  .why_sec .box.rose::before {
    background-position: right bottom;
  }

  .cta .box {
    padding: 24px;
  }

  .suppliers .round > div {
    width: 140px;
    height: 140px;
  }

  .suppliers .boxes {
    margin-top: 90px;
    margin-bottom: 110px;
  }

  .product .box {
    padding: 24px;
  }

  .slide_sec .box {
    padding: 30px;
    padding-bottom: 90px;
  }

  .slide_sec .box .usr_box h5 {
    font-size: 20px;
  }

  .slide_sec .box .usr_box .user img {
    height: 60px;
  }

  .slide_sec .slick-dots {
    bottom: -30px;
  }

  .slide_sec .slick-arrow {
    bottom: -55px;
  }

  .faq .accordion-button::after {
    height: 26px;
  }

  .faq .accordion-item button {
    padding-left: 50px;
  }
  .categories .listView .product-list-detail-box {
    flex-direction: column;
    align-items: flex-start;
    width: calc(100% - 75px);
  }
  .categories .listView .img_box img {
    width: 75px;
  }
  .categories .listView .list-3 {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .page_header h1 {
    font-size: 32px;
  }

  .why_sec .box {
    padding: 25px;
  }

  .why_sec .box img {
    width: 50%;
  }

  .suppliers .box > div {
    width: 110px;
  }

  .suppliers .boxes {
    margin-top: 40px;
    margin-bottom: 90px;
  }

  .product {
    padding-left: 7px;
    padding-right: 7px;
  }

  .product .box {
    gap: 10px;
  }

  .main_wrapper {
    overflow-x: hidden;
  }

  .product_slide .slick-prev {
    left: 0;
  }

  .product_slide .slick-next {
    right: 0;
  }
}

.secondary_btn {
  border: 1px solid #1ed6fb !important;
  border-radius: 150px;
  box-shadow: none !important;
  /* background: linear-gradient(106deg, #67ace4, #137ed4, #137ed4, #67ace4); */
  font-weight: 700;
  color: #000;
  padding: 10px 30px;
}

.badge1:after {
  content: attr(value);
  font-size: 10px;
  background: #137ed4;
  border-radius: 50%;
  padding: 3px;
  position: absolute;
  right: -6px;
  top: -7px;
  opacity: 0.9;
  color: #fff;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.text_line {
  text-decoration: line-through;
}

.activee {
  transition: 0.5s ease-out;
  color: #fff !important;
  background: linear-gradient(
    135deg,
    #f8f8ff -30%,
    #0c053b 29%,
    #0c053b 70%,
    #0c053b 76%,
    #f9f8ff 150%
  );
  border: 1px solid #0c053b;
}

.activee img {
  filter: brightness(0) invert(1);
}

a.dropdown-item {
  word-break: break-word;
  white-space: wrap;
}

a.dropdown-item:hover {
  background: #222 !important;
  color: #fff !important;
}

.scrl {
  max-height: 250px;
  overflow-y: auto;
}

.review_box {
  max-width: 50%;
  margin: 0 auto;
}

.review_box .text-group text {
  fill: #000 !important;
}

.campass_box {
  max-width: 50%;
  margin: 0 auto;
}

.campass_box .text-group text {
  fill: #000 !important;
}

.make_tab .tabs {
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}

.make_tab .tabs > div {
  font-weight: 600;
  font-size: 16px;
  color: #45436b;
  border-bottom: 2px solid transparent;
  box-shadow: none !important;
  padding: 0.5rem 1rem;
  padding-left: 0;
  padding-right: 0px;
  margin-right: 25px;
}

.make_tab .tabs > div.active {
  color: #0c053b;
  border-bottom: 2px solid #fae123;
}

.chart-btn {
  border: 2px solid #0066ee10;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #0066ee50;
  color: white;
}

.chart-btn-active {
  border: 2px solid #0066ee;
  background-color: #0066ee25;
  color: #0066ee;
}

/* .chart_bg rect{
  fill: #5a9cd2;
} */

.onetime div.rdt_TableCol:nth-child(4) {
  display: grid;
}

.onetime div.rdt_TableCol:nth-child(4):after {
  content: "(One Time Payment)";
  font-weight: 800;
}

.onetime1 div.rdt_TableCol:nth-child(5) {
  display: grid;
}

.onetime1 div.rdt_TableCol:nth-child(5):after {
  content: "(One Time Payment)";
  font-weight: 800;
}

.Scrol_Box_div {
  width: 50px !important;
  background-color: #232527 !important;
  color: #f1c40f !important;
}

.box.yellow {
  background: url("./assets/images/c1.jpeg") repeat center !important;
}

.box.green {
  background: url("./assets/images/c2.jpeg") repeat center !important;
}

.box.black {
  background: url("./assets/images/c3.jpeg") repeat center !important;
  color: white;
}

.box.grey {
  background: url("./assets/images/c4.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.box.lightgrey {
  background: url("./assets/images/c5.jpeg") no-repeat center !important;
  background-size: cover !important;
}

.box.violet {
  background: url("./assets/images/c6.jpeg") repeat center !important;
}

.box.Blue {
  background: url("./assets/images/1.jpg") repeat center !important;
}

.box.BlueBlack {
  background: url("./assets/images/6.jpg") repeat center !important;
}

.box.YellowGreeen {
  background: url("./assets/images/7.png") repeat center !important;
  color: white;
}

.box.LightGreen {
  background: url("./assets/images/2.jpg") repeat center !important;
}

.box.Orange {
  background: url("./assets/images/3.jpg") repeat center !important;
  background-size: cover !important;
}

.box.Purple {
  background: url("./assets/images/5.jpg") repeat center !important;
}

.market_btn.black span {
  color: #fff;
}

.flag_list {
  list-style: none;
  padding: 0;
}

.flag_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  cursor: pointer;
  border: 1px solid #ebebeb;
  padding: 10px;
  border-radius: 10px;
  transition: .3s;
}

.flag_list li:hover {
  color: #e3325c;
  border: 1px solid #e3325c;
  transition: .3s;
}

.flag_list li.active {
  color: #e3325c;
  border: 1px solid #e3325c;
}

[data-theme="dark"] .flag_list li {
  border: 1px solid #223151 ;
}
[data-theme="dark"] .flag_list li.active{
  border: 1px solid #892432;
  color: #e53e55;
}
[data-theme="dark"] .flag_list li:hover {
  border: 1px solid #e3325c;
  transition: .3s;
}